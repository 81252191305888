@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&display=swap);
@font-face {
  font-family: "Blender Pro Medium";
  src: local("Blender Pro Medium"), local("BlenderPro-Medium"),
    url(/static/media/BlenderPro-Medium.f2b23a9e.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: "Barlow", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}

input,
select,
textarea,
button {
  font-family: inherit;
  color: black;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --traverse-blue: #009cec;
  --traverse-semi-dark-blue: #008ee1;
  --traverse-dark-blue: #0080d6;
}

