body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --traverse-blue: #009cec;
  --traverse-semi-dark-blue: #008ee1;
  --traverse-dark-blue: #0080d6;
}
