@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "Blender Pro Medium";
  src: local("Blender Pro Medium"), local("BlenderPro-Medium"),
    url("BlenderPro-Medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: "Barlow", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}

input,
select,
textarea,
button {
  font-family: inherit;
  color: black;
}
